import produce, { Draft } from "immer";
import { getType } from "typesafe-actions";
import { RootAction } from "../actions";
import { ClientsState, Clients } from "../@types/clients";
import { clientsActions, clientsActionsAsync } from "../actions/clientsActions";
import { ClientCount } from "../@types/clients";


const initialClientsState: ClientsState = {
    clients: {},
    counts: [],
}

const clientsReducer = produce(
    (draft: ClientsState, action: RootAction) => {
        switch (action.type) {
            case getType(clientsActionsAsync.getClients.success):
                {
                    let clients: Clients = {};
                    action.payload.clients.forEach(client => clients[client.clientId] = client);
                    draft.clients = clients;
                }
                return;
            case getType(clientsActionsAsync.createClient.success):
                {
                    const client = action.payload.client;
                    draft.clients[client.clientId] = client;
                }
                return;
            case getType(clientsActionsAsync.editClient.success):
                {
                    const client = action.payload.client;
                    draft.clients[client.clientId] = client;
                }
                return;
            case getType(clientsActionsAsync.deleteClient.success):
                {
                    delete draft.clients[action.payload.clientId];
                }
                return;
            case getType(clientsActionsAsync.getClientsCounts.success):
                {
                    const newCounts = action.payload.counts;
                    let counts = JSON.parse(JSON.stringify(draft.counts));
                    // Merge counts, update old
                    newCounts.forEach(nc => {
                        const ncIndex = counts.findIndex((c: ClientCount) => c.id === nc.id);
                        if (ncIndex === -1) counts.push(nc); 
                        else counts[ncIndex] = nc;
                    });
                    draft.counts = counts;
                }
                return;
            case getType(clientsActionsAsync.editClientCounts.success):
                {
                    // Merge counts
                    const counts = action.payload.counts;
                    counts.forEach(count => {
                        const i = draft.counts.findIndex(c =>
                            c.classification_id === count.classification_id
                            && c.client_id === count.client_id
                        );
                        if (i >= 0) draft.counts[i] = count;
                        else draft.counts.push(count);
                    });
                }
                return;
        }
    },
    initialClientsState
);

export default clientsReducer;