import { createSelector } from "reselect";
import { IState } from "../@types";


export const countsSelector = (clientId?: string, classificationId?: string) => createSelector(
   (state: IState) => state.clients.counts,
   (state: IState) => state.users.user,
   (counts, user) => {
      if (!user) return null;
      if (clientId) {
         if (classificationId)
            return counts.filter(count => 
               count.client_id === clientId
               && count.classification_id === classificationId
            );
         else return counts.filter(count => count.client_id === clientId);
      } else if (user.role === "super") {
         if (classificationId)
            return counts.filter(
               count => count.client_id === ""
               && count.classification_id === classificationId
            );
         else return counts.filter(count => count.client_id === "");
      } else {
         if (classificationId)
            return counts.filter(count => 
               count.client_id === user.clientId
               && count.classification_id === classificationId
            );
         else return counts.filter(count => count.client_id === user.clientId);
      }
   //   if (clientId) {
   //      return counts.filter(count => count.client_id === clientId);
   //   } else if (user.role === "super") {
   //      return counts.filter(count => count.client_id === "");
   //   } else {
   //      return counts.filter(count => count.client_id === user.clientId);
   //   }
   }
);