import {
    createAction,
    createAsyncAction
} from "typesafe-actions";
import { LicenseTypes, LicenseType } from "../@types/licenses";


export const licensesActions = {}

export const licensesActionsAsync = {
    getLicenseTypes: createAsyncAction(
        '@licenses/getLicenseTypes/request',
        '@licenses/getLicenseTypes/success',
        '@licenses/getLicenseTypes/failure',
    )<
        {},
        { licenseTypes: LicenseType[] },
        {}
    >(),
    getClientLicences: createAsyncAction(
        '@licenses/getClientLicences/request',
        '@licenses/getClientLicences/success',
        '@licenses/getClientLicences/failure',
    )<
        { clientId: string },
        { counts: any[] },
        {}
    >(),
    addLicences: createAsyncAction(
        '@licenses/addLicences/request',
        '@licenses/addLicences/success',
        '@licenses/addLicences/failure',
    )<
        {
            type: string,
            customer_id: string,
            classification_id: string,
            no_of_reports: number,
            expiry_date: string,
            payment_ref_no: string,
            purchased_by: string,
            purchased_date: string
        },
        {},
        {}
    >(),
    allocateCustomerLicences: createAsyncAction(
        '@licenses/allocateCustomerLicences/request',
        '@licenses/allocateCustomerLicences/success',
        '@licenses/allocateCustomerLicences/failure',
    )<
        {
            username: string,
            customer_id: string,
            client_id: string,
            no_of_reports: number,
            classification_id: string,
        },
        {},
        {}
    >(),
    purchaseProduct: createAsyncAction(
        '@licenses/purchaseProduct/request',
        '@licenses/purchaseProduct/success',
        '@licenses/purchaseProduct/failure',
    )<
        {
            amount: number,
            quantity: number,
            currency: string,
            success_url: string,
            cancel_url: string,
            type: string,
            classification_id: string,
            no_of_reports: number,
            expiry_date?: string,
        },
        { redirect_url: string },
        {}
    >(),
    purchaseFulfil: createAsyncAction(
        '@licenses/purchaseFulfil/request',
        '@licenses/purchaseFulfil/success',
        '@licenses/purchaseFulfil/failure',
    )<
        { sessionId: string },
        {},
        {}
    >(),
}