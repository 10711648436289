import * as React from 'react';
import { useState, useMemo } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { useDispatch, useSelector } from 'react-redux';
import { IState } from "../../redux/@types";
import {
    Box,
    Typography,
    Card,
    CardContent,
    TextField,
    Button,
    Select,
    SelectChangeEvent,
    MenuItem,
    FormControl,
    InputLabel,
    FormHelperText,
    Autocomplete,
    Alert,
} from "@mui/material";
import { handleInputChange, handleSelectChange } from "../../utils/formHandlers";
import { usersActionsAsync } from "../../redux/actions/usersActions";
import { UserRole } from "../../redux/@types/users";
import {
    isValidPhoneNumber,
    isValidEmail,
    isValidPassword,
} from "../../utils/inputValidations";


interface AddUserProps extends RouteComponentProps {}

const AddUser: React.FC<AddUserProps> = () => {
    const dispatch = useDispatch();

    // State
    const currentUser = useSelector((state: IState) => state.users.user);
    const clients = useSelector((state: IState) => state.clients.clients)
    const [username, setUsername] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [jobTitle, setJobTitle] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [role, setRole] = useState<UserRole>("user");
    const [touched, setTouched] = useState<{ [id: string]: boolean }>({});
    const [clientId, setClientId] = useState<string>("");

    // Functions
    const handleTouched = (type: string) => () => {
        if (!touched[type]) setTouched({ ...touched, [type]: true });
    }
    const createUser = () => {
        dispatch(usersActionsAsync.createUser.request({
            user: {
                username,
                firstName,
                lastName,
                phoneNumber,
                email,
                jobTitle,
                role,
                clientId
            },
            password
        }));
    }

    const clientsOptions = useMemo(() => {
        let options: { clientId: string, label: string }[] = [];
        Object.values(clients).forEach(client => options.push({ clientId: client.clientId, label: client.clientName }));
        return options;
    }, [clients]);

    const usernameValid = username !== "";
    const firstNameValid = firstName !== "";
    const lastNameValid = lastName !== "";
    const emailValid = email && isValidEmail(email);
    const passwordMatch = password === confirmPassword;;
    const passwordStringValid = isValidPassword(password);
    const passwordValid = passwordStringValid && passwordMatch;
    const phoneNumberValid = !phoneNumber || isValidPhoneNumber(phoneNumber);
    const clientIdValid = !clientId || /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(clientId);
    const formValid = usernameValid
        && firstNameValid
        && lastNameValid
        && phoneNumberValid
        && emailValid
        && passwordValid
        && passwordMatch
        && clientIdValid;

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'end',
                marginBottom: '24px',
            }}>
                <Typography
                    variant="h4"
                >
                    Users
                </Typography>
                <Typography
                    variant="h6"
                    sx={{ marginLeft: '12px' }}
                >
                    / add
                </Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
            }}>
                <Box sx={{
                    display: 'flex',
                    width: '950px',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    '& > div': {
                        marginBottom: '20px'
                    },
                }}>
                    <Card sx={{
                        display: 'flex',
                        paddingTop: '12px',
                    }}>
                        <CardContent sx={{ width: '600px' }}>
                            <Typography variant="h6">
                                Basic information
                            </Typography>
                        </CardContent>
                        <CardContent sx={{
                            width: '100%',
                            '& > div': {
                                marginBottom: '20px'
                            },
                        }}>
                            <Box>
                                <TextField
                                    required
                                    fullWidth
                                    label="Username"
                                    placeholder="Username"
                                    value={username}
                                    onChange={handleInputChange(setUsername)}
                                    error={touched["username"] && !usernameValid}
                                    helperText={touched["username"] && !usernameValid && "Invalid username"}
                                    onBlur={handleTouched("username")}
                                />
                            </Box>
                            <Box>
                                <TextField
                                    required
                                    fullWidth
                                    label="First name"
                                    placeholder="First name"
                                    value={firstName}
                                    onChange={handleInputChange(setFirstName)}
                                    error={touched["firstName"] && !firstNameValid}
                                    helperText={touched["firstName"] && !firstNameValid && "Invalid first name"}
                                    onBlur={handleTouched("firstName")}
                                />
                            </Box>
                            <Box>
                                <TextField
                                    required
                                    fullWidth
                                    label="Last name"
                                    placeholder="Last name"
                                    value={lastName}
                                    onChange={handleInputChange(setLastName)}
                                    error={touched["lastName"] && !lastNameValid}
                                    helperText={touched["lastName"] && !lastNameValid && "Invalid last name"}
                                    onBlur={handleTouched("lastName")}
                                />
                            </Box>
                            <Box>
                                <TextField
                                    fullWidth
                                    label="Phone number"
                                    placeholder="Phone number"
                                    value={phoneNumber}
                                    onChange={handleInputChange(setPhoneNumber)}
                                    error={touched["phoneNumber"] && !phoneNumberValid}
                                    helperText={`${touched["phoneNumber"] && !phoneNumberValid && "Invalid phone number format. " || ""}Please use international format`}
                                    onBlur={handleTouched("phoneNumber")}
                                />
                            </Box>
                            <Box>
                                <TextField
                                    required
                                    fullWidth
                                    label="Email address"
                                    placeholder="Email address"
                                    value={email}
                                    onChange={handleInputChange(setEmail)}
                                    error={touched["email"] && !emailValid}
                                    helperText={touched["email"] && !emailValid && "Invalid email format"}
                                    onBlur={handleTouched("email")}
                                />
                            </Box>
                            <Box>
                                <TextField
                                    fullWidth
                                    label="Job title"
                                    placeholder="Job title"
                                    value={jobTitle}
                                    onChange={handleInputChange(setJobTitle)}
                                    onBlur={handleTouched("jobTitle")}
                                />
                            </Box>
                            <Box>
                                <TextField
                                    required
                                    fullWidth
                                    label="Password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={handleInputChange(setPassword)}
                                    error={touched["password"] && !(passwordValid)}
                                    helperText={touched["password"] && !passwordValid && `${!passwordStringValid && "Invalid password. Requires at least 15 characters, an uppercase, a lowercase, a number, and a special character." || ""}${!passwordStringValid && !passwordMatch && "; " || ""}${!passwordMatch && "Password does not match." || ""}`}
                                    onBlur={handleTouched("password")}
                                />
                            </Box>
                            <Box>
                                <TextField
                                    required
                                    fullWidth
                                    label="Confirm password"
                                    placeholder="Confirm password"
                                    value={confirmPassword}
                                    onChange={handleInputChange(setConfirmPassword)}
                                    error={touched["confirmPassword"] && !(passwordMatch)}
                                    helperText={touched["confirmPassword"] && !passwordMatch && "Password does not match"}
                                    onBlur={handleTouched("confirmPassword")}
                                />
                            </Box>
                        </CardContent>
                    </Card>
                    <Card sx={{
                        display: 'flex',
                        paddingTop: '12px',
                    }}>
                        <CardContent sx={{ width: '600px' }}>
                            <Typography variant="h6">
                                User properties
                            </Typography>
                        </CardContent>
                        <CardContent sx={{
                            width: '100%',
                            '& > div': {
                                marginBottom: '20px'
                            },
                        }}>
                            <Box>
                                <FormControl
                                    fullWidth
                                    required
                                >
                                    <InputLabel id="user-role-select-label">User level</InputLabel>
                                    <Select
                                        labelId="user-role-select-label"
                                        value={role}
                                        onChange={handleSelectChange(setRole)}
                                        label="User role"
                                    >
                                        <MenuItem value="user">User</MenuItem>
                                        <MenuItem value="admin">Admin</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            {currentUser && currentUser.role === "super" && <Box>
                            <Autocomplete
                                disablePortal
                                id="combo-box-clients"
                                options={clientsOptions}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Client" />}
                                onChange={(e, value) => value && setClientId(value.clientId)}
                            />
                                {/* <TextField
                                    fullWidth
                                    label="Client ID"
                                    placeholder="Client ID"
                                    value={clientId}
                                    onChange={handleInputChange(setClientId)}
                                    error={touched["clientId"] && !clientIdValid}
                                    helperText={touched["clientId"] && !clientIdValid && "Invalid ID format"}
                                    onBlur={handleTouched("clientId")}
                                /> */}
                            </Box>}
                        </CardContent>
                    </Card>
                    <Box>
                        <Alert severity="warning">New users can consume licences.</Alert>
                    </Box>
                    <Box sx={{
                        marginTop: '16px',
                        '.action-btn': {
                            marginLeft: '10px',
                            fontSize: '16px',
                        },
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                        <Box />
                        <Box>
                            <Button
                                className="action-btn"
                                variant="outlined"
                                sx={{ textTransform: 'none' }}
                                onClick={() => navigate("/app/users")}
                            >Cancel</Button>
                            <Button
                                disabled={!formValid}
                                className="action-btn"
                                variant="contained"
                                sx={{ textTransform: 'none' }}
                                onClick={createUser}
                            >Submit</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default AddUser;