import * as React from 'react';
import { useState, useMemo, useEffect } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { useSelector } from 'react-redux';
import { IState } from '../../redux/@types';
import {
    Box,
    Button,
    Typography,
    Paper,
    InputBase,
    Divider,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TableSortLabel,
    Tooltip,
} from "@mui/material";
import {
    Search as SearchIcon,
    Edit as EditIcon,
    ArrowForward as ArrowForwardIcon,
    Add as AddIcon,
} from "@mui/icons-material";
import { User } from '../../redux/@types/users';
// import { LicenseType } from '../../redux/@types/licenses';
import { ClientCount } from '../../redux/@types/clients';
import debounce from "lodash.debounce";
import { handleInputChange } from '../../utils/formHandlers';
import { usersSelector } from '../../redux/selectors/usersSelector';


const rowsPerPageOptions = [10, 25, 50];
const headCells: {
    id: string,
    numeric: boolean,
    label: string,
    width?: string,
    sortable: boolean,
    align?: "right" | "left" | "center",
    hide?: boolean
}[] = [
    {
        id: 'type',
        numeric: false,
        label: 'TYPE',
        width: '15%',
        sortable: true
    },
    {
        id: 'name',
        numeric: false,
        label: 'NAME',
        width: '30%',
        sortable: true
    },
    {
        id: 'purchased_date',
        numeric: true,
        label: 'PURCHASE DATE',
        width: '20%',
        sortable: true
    },
    {
        id: 'expiry_date',
        numeric: false,
        label: 'EXPIRY DATE',
        width: '20%',
        sortable: true
    },
    {
        id: 'no_of_reports',
        numeric: true,
        label: 'NO. REPORTS',
        width: '5%',
        sortable: true
    },
    {
        id: 'remaining',
        numeric: true,
        label: 'REMAINING',
        width: '5%',
        sortable: true
    },
    {
        id: 'actions',
        numeric: false,
        label: 'ACTIONS',
        sortable: false,
        width: '5%',
        align: 'right'
    }
]

interface LicencesIndexProps extends RouteComponentProps {}

const LicencesIndex: React.FC<LicencesIndexProps> = () => {
    // State
    const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageOptions[0]);
    const [page, setPage] = useState<number>(0);
    const licences = useSelector((state: IState) => state.clients.counts);
    const classifications = useSelector((state: IState) => state.forms.classifications);
    const [filteredLicences, setFilteredLicences] = useState<ClientCount[]>(licences);
    const [orderBy, setOrderBy] = useState<"type" | "name" | "purchased_date" | "expiry_date" | "no_of_reports" | "remaining">("type");
    const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
    const user = useSelector((state: IState) => state.users.user);

    const isAdmin = user && (user.role === 'admin' || user.role === 'super');

    useEffect(() => {
        headCells.forEach((d) => {
            if (d.id === "actions" && !isAdmin) d.hide = true;
        });
    }, [user]);
    const rows = useMemo(() => {
        return filteredLicences.map(l => {
            return {
                name: classifications[l.classification_id]?.description || "",
                ...l,
            };
        });
    } , [filteredLicences]);

    useEffect(() => {
        filterLicences("");
    }, [licences]);

    const sorted = useMemo(() => {
        let sorted = rows;
        sorted.sort((a, b) => {
            if (!a.type) return 1;
            if (!b.type) return -1;
            const aType = a.type.toLowerCase();
            const bType = b.type.toLowerCase();
            if (aType > bType) return -1;
            else return 1;
        });
        if (orderBy === "purchased_date" || orderBy === "expiry_date") {
            sorted.sort((a, b) => {
                if (!a[orderBy]) return 1;
                if (!b[orderBy]) return -1;
                return sortOrder === "desc" ? new Date(b[orderBy]).getTime() - new Date(a[orderBy]).getTime()
                    : new Date(a[orderBy]).getTime() - new Date(b[orderBy]).getTime(); 
            });
        } else if (orderBy === "no_of_reports" || orderBy === "remaining") {
            sorted.sort((a, b) => {
                if (sortOrder === "desc") {
                    return b[orderBy] - a[orderBy];
                } else {
                    return a[orderBy] - b[orderBy];
                }
            });
        } else {
            sorted.sort((a, b) => {
                if (!a[orderBy]) return 1;
                if (!b[orderBy]) return -1;
                // Case insensitive sort
                const aOrderBy = a[orderBy].toLowerCase();
                const bOrderBy = b[orderBy].toLowerCase();
                if (sortOrder === "desc") {
                    if (aOrderBy < bOrderBy) return -1;
                    if (aOrderBy > bOrderBy) return 1;
                } else {
                    if (aOrderBy > bOrderBy) return -1;
                    if (aOrderBy < bOrderBy) return 1;
                }
                return 0;
            });
        }
        return sorted;
    }, [rows, sortOrder, orderBy]);

    // Functions
    const filterLicences = (s: string) => {
        const licenceList = Object.values(licences);
        if (!s) return setFilteredLicences(licenceList);

        const lowerS = s.toLowerCase();
        const debouncedFilter = debounce(() => {
            setFilteredLicences(licenceList.filter(l => {
                return l.type.toLowerCase().includes(lowerS);
            }));
        }, 500); // 500ms debounce
        debouncedFilter();
    }
    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page
    }

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '24px',
            }}>
                <Typography variant="h4">Licences</Typography>
            </Box>
            <Paper>
                <Box>
                    <Box sx={{
                        padding: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}>
                            <Box sx={{ display: 'flex', flexGrow: 1 }}>
                                <IconButton sx={{ p: '5px' }}>
                                    <SearchIcon />
                                </IconButton>
                                <InputBase
                                    id="user-search"
                                    placeholder="Search licences"
                                    sx={{
                                        marginLeft: '24px',
                                        width: '100%'
                                    }}
                                    onChange={handleInputChange(filterLicences)}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Divider />
                    <Box>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {
                                        headCells.map(headCell => (
                                            <TableCell
                                                key={headCell.id}
                                                width={headCell.width}
                                                align={headCell.align || undefined}
                                                sx={{ ...(headCell.hide === true ? { display: 'none' } : null) }}
                                            >
                                                {
                                                    headCell.sortable ? 
                                                        <TableSortLabel
                                                            direction={sortOrder}
                                                            onClick={() => {
                                                                if (orderBy !== headCell.id) {
                                                                    // @ts-ignore
                                                                    setOrderBy(headCell.id)
                                                                    setSortOrder("asc");
                                                                } else {
                                                                    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                                                                }
                                                            }}
                                                        >
                                                            <Typography variant="body2">
                                                                {headCell.label}
                                                            </Typography>
                                                        </TableSortLabel> :
                                                        <Typography variant="body2">
                                                            {headCell.label}
                                                        </Typography>
                                                }
                                            </TableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ wordBreak: 'break-all' }}>
                                {sorted
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => (
                                    <TableRow
                                        key={`licence-${row.id}`}
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 },
                                            'p': {
                                                wordBreak: 'break-word',
                                            }
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            <Typography variant="body2">
                                                {row.type}
                                            </Typography>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Typography variant="body2">
                                                {row.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2">
                                                {row.purchased_date && new Date(row.purchased_date).toLocaleString() || ""}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2">
                                                {row.expiry_date && new Date(row.expiry_date).toLocaleString() || ""}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2">
                                                {row.no_of_reports}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2">
                                                {row.remaining}
                                            </Typography>
                                        </TableCell>
                                        {isAdmin && <TableCell align="right">
                                            <Box>
                                                <Tooltip title={"To store"}>
                                                    <IconButton
                                                        onClick={() => navigate(`/app/store`)}
                                                    >
                                                        <ArrowForwardIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </TableCell>}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <Divider />
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptions}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
};

export default LicencesIndex;