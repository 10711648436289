import * as React from 'react';
import { useState, useMemo, ChangeEvent, useEffect } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { useDispatch, useSelector } from 'react-redux';
import { countsSelector } from '../../redux/selectors/countsSelector';
import { IState } from "../../redux/@types";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Typography,
    Divider,
    Button,
    Modal,
    TextField,
} from "@mui/material";
import {
    Edit as EditIcon,
} from "@mui/icons-material";
import EditClient from "./EditClient";
import { clientsActionsAsync } from "../../redux/actions/clientsActions";
import { licensesActionsAsync } from '../../redux/actions/licensesActions';


interface ClientDetailsProps extends RouteComponentProps {}

const ClientDetails: React.FC<ClientDetailsProps> = () => {
    const dispatch = useDispatch();

    const pathname = typeof window !== "undefined" && window.location.pathname;
    const hash = typeof window !== "undefined" && window.location.hash;
    const routes = pathname && pathname.split('/') || [];
    const edit = hash === "#edit";
    const clientId = routes[routes.length-1];

    // State
    const user = useSelector((state: IState) => state.users.user);
    const classifications = useSelector((state: IState) => state.forms.classifications);
    const customerCounts = useSelector(countsSelector());
    const client = useSelector((state: IState) => state.clients.clients[clientId]);
    const clientCounts = useSelector(countsSelector(client?.clientId));
    const [editedCounts, setEditedCounts] = useState<{ [classificationId: string]: number }>({});
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [allocateModalOpen, setAllocateModalOpen] = useState<boolean>(false);

    // Functions
    const deleteClient = () => {
        if (client) {
            dispatch(clientsActionsAsync.deleteClient.request({ clientId: client.clientId }));
            setModalOpen(false);
            navigate("/app/clients");
        }
    }
    const editClientCounts = () => {
        if (!client) return;
        const formatted = Object.entries(editedCounts).map((v) => {
            return {
                username: user?.username || "",
                customer_id: user?.customerId || "",
                classification_id: v[0],
                client_id: clientId,
                no_of_reports: v[1],
            }
        });
        formatted.forEach(f => {
            dispatch(licensesActionsAsync.allocateCustomerLicences.request(f));
        });
        setAllocateModalOpen(false);
        setEditedCounts({}); // Reset counts
    }
    const countsOnChange = (classificationId: string) => (e: ChangeEvent<HTMLInputElement>) => {
        const newEditedCounts = {
            ...editedCounts,
            [classificationId]: +e.target.value >= 0 ? +e.target.value : 0
        };
        setEditedCounts(newEditedCounts);
    }

    useEffect(() => {
        if (!clientId) return;
        dispatch(licensesActionsAsync.getClientLicences.request({ clientId }));
    }, []);
    const countsForm = useMemo(() => {
        const classificationIds = Object.keys(classifications);
        return (
            <Box>
                {classificationIds.map((classificationId, i) => {
                    const customerClassificationCount = customerCounts?.reduce((a, c) => {
                        if (c.classification_id === classificationId) return a + c.available_reports;
                        else return a;
                    }, 0);
                    const clientClassificationCount = clientCounts?.reduce((a, c) => {
                        if (c.classification_id === classificationId) return a + c.available_reports;
                        else return a;
                    }, 0);
                    return (
                        <Box key={`counts-${classificationId}`} sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            <Box sx={{ height: '10px' }}/>
                            <Typography variant="h6">{classifications[classificationId].description}</Typography>
                            <Box sx={{ height: '10px' }}/>
                            <TextField
                                type="number"
                                value={editedCounts[classificationId] || 0}
                                onChange={countsOnChange(classificationId)}
                                size="small"
                                sx={{ width: '150px' }}
                                label="Add licenses"
                            />
                            <Box sx={{ height: '10px' }}/>
                            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>Licenses</Typography>
                            <Box sx={{ display: 'flex'}}>
                                <Box>
                                    <Typography variant="body1" sx={{ width: "150px" }}>
                                        {`Yours:`}
                                    </Typography>
                                    <Typography variant="body1" sx={{ width: "150px" }}>
                                        {`Client's: `}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="body1">{`${customerClassificationCount || 0} >> ${(customerClassificationCount || 0) - (editedCounts[classificationId] || 0)}`}</Typography>
                                    <Typography variant="body1">{`${clientClassificationCount || 0} >> ${(clientClassificationCount || 0) + (editedCounts[classificationId] || 0)}`}</Typography>
                                </Box>
                            </Box>
                            {classificationIds && classificationIds.length-1 !== i && <Divider sx={{ padding: '5px' }}/>}
                        </Box>
                    );
                })}
            </Box>
        );
    }, [customerCounts, classifications, client, editedCounts]);
    const items = useMemo(() => {
        return client && [
            { key: "clientid", title: "Client ID", value: client.clientId },
            { key: "clientname", title: "Client name", value: client.clientName },
        ] || [];
    }, [client]);

    if (!client) navigate("/app/clients");

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                marginBottom: '24px',
                justifyContent: 'space-between'
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'end',
                }}>
                    <Typography
                        variant="h4"
                    >
                        Clients
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{ marginLeft: '12px' }}
                    >
                        / {client && client.clientName} {edit && "/ edit"}
                    </Typography>
                </Box>
                {!edit && <Button
                    variant="contained"
                    onClick={() => navigate(`/app/clients/${clientId}#edit`) }
                >
                    <EditIcon fontSize="small"/>
                    <Typography sx={{
                        marginLeft: '3px',
                        textTransform: 'none',
                        fontSize: '18px',
                    }}>Edit</Typography>
                </Button>}
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
            }}>
                <Box sx={{
                    display: 'flex',
                    width: '950px',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    '& > div > div': {
                        marginBottom: '20px'
                    },
                }}>
                    {edit && <EditClient clientId={clientId} />}
                    {!edit && <Box>
                        <Card>
                            <CardHeader title="Basic information"/>
                            <Divider />
                            {items.map((item, i) => (
                                <Box key={item.key}>
                                    <CardContent sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                width: '250px',
                                            }}
                                        >{item.title}</Typography>
                                        <Typography variant="body2">{item.value}</Typography>
                                    </CardContent>
                                    {i !== items.length-1 && <Divider />}
                                </Box>
                            ))}
                        </Card>
                        <Card>
                            <CardHeader title="Client management"/>
                            <Divider />
                            <CardContent>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        marginBottom: '10px',
                                    }}
                                >Allocate licenses</Typography>
                                <Button
                                    variant="contained"
                                    sx={{ textTransform: 'none' }}
                                    onClick={() => setAllocateModalOpen(true)}
                                >Allocate</Button>
                            </CardContent>
                            <Divider />
                            <CardContent>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        marginBottom: '10px',
                                    }}
                                >Delete client</Typography>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    sx={{ textTransform: 'none' }}
                                    onClick={() => setModalOpen(true)}
                                >Delete</Button>
                            </CardContent>
                        </Card>
                    </Box>}
                </Box>
            </Box>
            <Modal
                open={allocateModalOpen}
                onClose={() => setAllocateModalOpen(false)}
                sx={{
                    position: 'absolute',
                    top: '10%',
                    left: '20%',
                    width: '60%',
                    height: '80%',
                    minWidth: '400px',
                    minHeight: '400px',
                    bgcolor: 'background.paper',
                    borderRadius: '3px',
                    overflow: 'auto',
                }}
            >
                <Card>
                    <CardHeader title={`Allocate licenses for ${client && client.clientName}`} />
                    <Divider />
                    <CardContent
                        sx={{
                            height: '92%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Box>
                            {countsForm}
                        </Box>
                        <Divider sx={{ margin: '16px 0 16px 0' }} />
                        <Button
                            variant="contained"
                            onClick={editClientCounts}
                        >Allocate</Button>
                    </CardContent>
                </Card>
            </Modal>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
            >
                <Card sx={{
                    position: 'absolute',
                    top: 'calc(50% - 100px)',
                    left: 'calc(50% - 200px)',
                    width: '400px',
                    bgcolor: 'background.paper',
                    borderRadius: '3px'
                }}>
                    <CardHeader title={`Delete ${client && client.clientName}?`} />
                    <Divider />
                    <CardContent
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={deleteClient}
                        >Delete</Button>
                    </CardContent>
                </Card>
            </Modal>
        </Box>
    );
};

export default ClientDetails;